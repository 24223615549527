import React, { FunctionComponent, useEffect, useRef } from 'react';
import './TypeOverSection.scss';
import classNames from 'classnames';

/*
Component that shows the text that the typist has typed along with the text they should type.
 */

type TypeOverSectionProps = {
  target: string;
  typed: string;
  current: boolean;
  hasError: boolean;
  onCurrentPositionChanged: (position: number) => void;
};

export const TypeOverSection: FunctionComponent<TypeOverSectionProps> = ({
  target,
  typed,
  current,
  hasError,
  onCurrentPositionChanged,
}) => {
  const ref = useRef(null);

  if (ref && ref.current) {
    const { offsetTop } = ref.current;
    setTimeout(() => {
      onCurrentPositionChanged(offsetTop);
    }, 0);
  }

  const remainingStart = typed.length > 0 ? typed.length : 0;
  const remaining = target.slice(remainingStart);

  const typedWithSpaces = typed.replaceAll(' ', '\u00A0');
  const remainingWithSpaces = remaining.replaceAll(' ', '\u00A0');
  const theClassNames = classNames({
    TypeOverSection: true,
    current: current,
    'has-error': hasError,
  });

  return (
    <div className={theClassNames} ref={ref}>
      {typedWithSpaces.length > 0 && (
        <span className="typed">{typedWithSpaces}</span>
      )}
      {remainingWithSpaces.length > 0 && (
        <span className="target">{remainingWithSpaces}</span>
      )}
    </div>
  );
};
