import React, { FunctionComponent, ReactElement } from 'react';
import './DrillMeasurementTrendChart.scss';
import { DrillMeasurement } from '../../../model/DrillMeasurement';
import { Goal } from '../../../model/Goal';
import {
  ceilToValue,
  calculateOpacityFromAccuracy,
  createScale,
} from '../../../util/math-utils';

type DrillMeasurementTrendChartProps = {
  drillMeasurements: DrillMeasurement[];
  goal: Goal;
  onSelectMeasurement: (measurement: DrillMeasurement | null) => void;
  onShowMeasurement: (newMeasurement: DrillMeasurement | null) => void;
  shownMeasurement: DrillMeasurement | null;
};

export const DrillMeasurementTrendChart: FunctionComponent<
  DrillMeasurementTrendChartProps
> = ({
  drillMeasurements,
  goal,
  onSelectMeasurement,
  onShowMeasurement,
  shownMeasurement,
}) => {
  if (drillMeasurements.length === 0) {
    return null;
  }

  const maxMeasuredWPM = drillMeasurements.reduce(
    (accumulator: number, measurement) =>
      Math.max(measurement.getWPM(), accumulator),
    0
  );

  // make sure we have plenty of room to show bars, but not a stupid amount of whitespace
  const maxWPMRange = ceilToValue(
    Math.max(maxMeasuredWPM, goal.getGoalWPM()) + 5,
    25
  );

  const calculateTop = createScale(0, maxWPMRange, 100, 1);

  const totalElapsedTime = drillMeasurements.reduce(
    (accumulator: number, measurement) =>
      (accumulator += measurement.getElapsedMinutes() * 60),
    0
  );
  const totalTime = Math.max(30 * 60, totalElapsedTime);

  const calculateWidthPercentage = createScale(0, totalTime, 0, 100);
  const calculateLeftPercentage = createScale(0, totalTime, 100, 0);

  // show the next goal
  const futureGoalTime = 35;
  const futureGoalTop = calculateTop(goal.getGoalWPM());
  const futureLeftPercentage = calculateLeftPercentage(futureGoalTime);

  const futureGoalStyle = {
    top: `${futureGoalTop}%`,
    left: `${futureLeftPercentage}%`,
    width: `${futureLeftPercentage}%`,
    height: `1px`,
  };

  const goalDiv = (
    <div key="future-goal" className="goal-div future" style={futureGoalStyle}>
      &nbsp;
    </div>
  );

  const measurementDivs: ReactElement[] = [];

  let accumulatedTimeInSeconds: number = futureGoalTime;

  // start with the most recent measurement

  drillMeasurements.forEach((drillMeasurement: DrillMeasurement, index) => {
    const wpm = drillMeasurement.getWPM();

    const leftPercentage = calculateLeftPercentage(
      accumulatedTimeInSeconds + drillMeasurement.getElapsedMinutes() * 60
    );

    const widthPercentage = calculateWidthPercentage(
      drillMeasurement.getElapsedMinutes() * 60
    );

    const top = calculateTop(wpm);

    const opacity = calculateOpacityFromAccuracy(
      drillMeasurement.getAccuracy()
    );

    const drillMeasurementStyle = {
      top: `${top}%`,
      left: `${leftPercentage}%`,
      width: `${widthPercentage}%`,
      height: `${100 - top}%`,
      opacity: opacity,
    };

    accumulatedTimeInSeconds += drillMeasurement.getElapsedMinutes() * 60;

    const measurementIsShown =
      drillMeasurement.getUnixStamp() === shownMeasurement?.getUnixStamp();
    const shownClass = measurementIsShown ? ' shown' : '';

    const measurementClass = 'measurement-div' + shownClass;

    measurementDivs.push(
      <div
        key={'measurement' + index}
        className={measurementClass}
        style={drillMeasurementStyle}
        onMouseEnter={() => onShowMeasurement(drillMeasurement)}
        onMouseLeave={() => onShowMeasurement(null)}
        onMouseDown={() => onSelectMeasurement(drillMeasurement)}
      >
        &nbsp;
      </div>
    );

    if (measurementIsShown) {
      const borderStyle = {
        top: `${top}%`,
        left: `${leftPercentage}%`,
        width: `${widthPercentage}%`,
        height: `${100 - top}%`,
      };
      measurementDivs.push(
        <div
          key={'measurement-border' + index}
          className="measurement-border"
          style={borderStyle}
          onMouseEnter={() => onShowMeasurement(shownMeasurement)}
          onMouseLeave={() => onShowMeasurement(null)}
          onMouseDown={() => onSelectMeasurement(shownMeasurement)}
        >
          &nbsp;
        </div>
      );
    }
  });

  const createShownLine = () => {
    if (!shownMeasurement || drillMeasurements.length < 5) {
      return null;
    }
    const shownLineTop = calculateTop(shownMeasurement.getWPM());
    const shownLineOpacity = calculateOpacityFromAccuracy(
      shownMeasurement.getAccuracy()
    );

    const shownLineStyle = {
      top: `${shownLineTop}%`,
      width: `${100 * (1 - futureGoalTime / totalTime)}%`,
      opacity: shownLineOpacity,
    };

    return (
      <div className="shown-line" style={shownLineStyle}>
        &nbsp;
      </div>
    );
  };

  return (
    <div className="DrillMeasurementTrendChart">
      {measurementDivs}
      {goalDiv}
      {createShownLine()}
    </div>
  );
};
