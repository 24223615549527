import { SectionListGenerator } from '../../../model/SectionListGenerator';
import { ChangeEvent, FunctionComponent } from 'react';
import { CustomGenerator } from '../../WordGenerators/CustomGenerator/CustomGenerator';
import { CustomGeneratorEditor } from '../../WordGenerators/CustomGenerator/CustomGeneratorEditor';
import { PatternGeneratorEditor } from '../../WordGenerators/PatternGenerator/PatternGeneratorEditor';

import './GeneratorEditor.scss';
import { PatternGenerator } from '../../WordGenerators/PatternGenerator/PatternGenerator';

type GeneratorEditorProps = {
  generator: SectionListGenerator;
  generators: SectionListGenerator[];
  onGeneratorChanged: (generator: SectionListGenerator) => void;
};

export const GeneratorEditor: FunctionComponent<GeneratorEditorProps> = ({
  generator,
  generators,
  onGeneratorChanged,
}) => {
  const options = generators.map((generator) => {
    return (
      <option key={generator.getName()} value={generator.getName()}>
        {generator.getName()}
      </option>
    );
  });

  const onGeneratorSelected = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedGenerator = generators.find((generator) => {
      return generator.getName() === event.target.value;
    });
    selectedGenerator && onGeneratorChanged(selectedGenerator);
  };

  let details = null;
  if (generator instanceof CustomGenerator) {
    details = (
      <CustomGeneratorEditor
        generator={generator}
        onGeneratorChanged={onGeneratorChanged}
      />
    );
  }
  if (generator instanceof PatternGenerator) {
    details = (
      <PatternGeneratorEditor
        generator={generator}
        onGeneratorChanged={onGeneratorChanged}
      />
    );
  }

  const onChangeScramble = () => {
    const newGenerator = generator.patchScramble(!generator.getScramble());
    onGeneratorChanged(newGenerator);
  };

  return (
    <div className="GeneratorEditor">
      <div className="selector">
        <div className="label">Generator</div>
        <select onChange={onGeneratorSelected} value={generator.getName()}>
          {options}
        </select>
        {generator.isScrambleSupported() && (
          <div>
            <input
              type="checkbox"
              onChange={onChangeScramble}
              checked={generator.getScramble()}
            />
            <span>Scramble words</span>
          </div>
        )}
      </div>
      <div className="details">{details}</div>
    </div>
  );
};
