import { FunctionComponent } from 'react';
import './StaticContent.scss';
import { getFullAppName } from '../../../util/app-name';

export const Welcome1: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">Words and Goals</div>
      <p>
        You can select from a number of patterns and then set limits on the
        length of the words. Based on your goal, {getFullAppName()} will
        generate enough words to let you meet your goal.
      </p>
      <div className="static-content-title">Focus Level</div>
      <p>
        The normal and focused levels present all of the words at once. The zen
        focus level presents one word at a time.
      </p>
    </div>
  );
};
