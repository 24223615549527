import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import './SimpleTypingGame.scss';

import { Section } from '../../../model/Section';
import { TypeOverSection } from '../../TypeOverSection/TypeOverSection';
import { FocusLevel } from '../../../model/FocusLevel';
import { getKey } from '../../../util/enum-utils';

/*
SimpleTypingGame is a component that steps the typist through sections until they
have completed the drill. Each section is presented to the typist as an TypeOverSection
that must be completed correctly any errors within a section must be fixed by backspacing
the whole section.
 */

type SimpleDrillProps = {
  sections: Section[];
  onDrillFinished: () => void;
  onEscape: () => void;
  focusLevel: FocusLevel;
  allowEscape: boolean;
};

export const SimpleTypingGame: FunctionComponent<SimpleDrillProps> = ({
  sections,
  onDrillFinished,
  onEscape,
  focusLevel,
  allowEscape,
}) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  const [typed, setTyped] = useState<string>('');
  const [done, setDone] = useState<boolean>(false);
  const [currentPosition, setCurrentPosition] = useState<number>(0);
  const inputRef = useRef(null);

  useEffect(() => {
    setCurrentSectionIndex(0);
    setDone(false);
    setTyped('');
  }, [sections]);

  useEffect(() => {
    if (done && typed !== '') {
      onDrillFinished();
      setTyped('');
    }
  }, [onDrillFinished, done, typed]);

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (done) {
      return;
    }

    if (event.code === 'Escape') {
      onEscape();
      return;
    }

    const currentSection = sections[currentSectionIndex];
    currentSection.type(event);
    setTyped(currentSection.getTyped());
  };

  const currentSection = sections[currentSectionIndex];
  if (
    !done &&
    currentSection.isComplete() &&
    currentSection.isCorrect() &&
    currentSectionIndex < sections.length - 1
  ) {
    setCurrentSectionIndex(currentSectionIndex + 1);
    setTyped('');
  }

  if (
    currentSectionIndex === sections.length - 1 &&
    currentSection.isComplete() &&
    currentSection.isCorrect()
  ) {
    if (!done) {
      setDone(true);
    }
  }

  const onCurrentPositionChanged = (position: number) => {
    setCurrentPosition(position);
  };

  const typeOverSections = sections.map((section: Section, index: number) => {
    return currentSection === section ? (
      <TypeOverSection
        onCurrentPositionChanged={onCurrentPositionChanged}
        key={index}
        target={section.getTarget()}
        typed={typed}
        current={true}
        hasError={!currentSection.isCorrect()}
      />
    ) : (
      <TypeOverSection
        onCurrentPositionChanged={() => {}}
        key={index}
        target={section.getTarget()}
        typed={section.getTyped()}
        current={false}
        hasError={false}
      />
    );
  });

  const inputStyle = {
    top: `${currentPosition + 200}px`,
  };

  const key = getKey(FocusLevel, focusLevel);
  const className = 'SimpleTypingGame ' + key;
  return (
    <div className={className}>
      <div className="typing">{typeOverSections}</div>
      {!done && (
        <input
          ref={inputRef}
          autoFocus={true}
          onKeyDown={onKeyDown}
          style={inputStyle}
        />
      )}
      {!done && (
        <div className="escape-message">
          Type when ready.
          {allowEscape && (
            <span>Press esc to exit without recording your results.</span>
          )}
        </div>
      )}
    </div>
  );
};
