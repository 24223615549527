import { DrillMeasurement } from '../model/DrillMeasurement';
import { Goal } from '../model/Goal';

const fireApiUrl = 'https://yatp-app.uc.r.appspot.com';
const localFireURL = 'http://localhost:8080';

function buildURL(path: string) {
  const basePath = window.location.href.includes('localServer')
    ? localFireURL
    : fireApiUrl;
  return basePath + '/' + path + '/';
}

export async function saveGoal(userGUID: string, goal: Goal) {
  const url = buildURL('goal');
  const goalDTO = goal.toDTO();
  const data = { goalDTO: goalDTO, userGUID };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions);
}

export async function loadGoal(userGUID: string) {
  const url = buildURL(`goal/${userGUID}`);
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(url, requestOptions);
}

export async function saveDrillMeasurements(
  userGUID: string,
  measurements: DrillMeasurement[]
) {
  const url = buildURL('drillMeasurements');
  const measurementDTOs = measurements.map((measurement) => {
    return measurement.toDTO();
  });
  const data = { measurementDTOs: measurementDTOs, userGUID };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(url, requestOptions);
}

export async function loadDrillMeasurements(userGUID: string) {
  const url = buildURL(`drillMeasurements/${userGUID}`);
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(url, requestOptions);
}
