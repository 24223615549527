import { DrillMeasurement } from '../model/DrillMeasurement';

export function uniqueMeasurements(allMeasurements: DrillMeasurement[]) {
  const uniqueMeasurements = [];
  const stamps: number[] = [];

  for (const measurement of allMeasurements) {
    const stamp = measurement.getUnixStamp();
    if (!stamps.includes(stamp)) {
      uniqueMeasurements.push(measurement);
      stamps.push(stamp);
    }
  }

  return uniqueMeasurements;
}
