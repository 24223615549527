import { FunctionComponent } from 'react';
import './StaticContent.scss';
import { getFullAppName } from '../../../util/app-name';

export const About: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">Progression</div>
      <p>
        Meet the WPM goal and the accuracy goal to get a new set of words. Oh
        and the goal will get just a little bit harder than the last goal!
      </p>
      <div className="static-content-title">Settings</div>
      <p>
        You can select from a number of patterns and then set limits on the
        length of the words. Based on your goal, {getFullAppName()} will
        generate enough words to let you meet your goal.
      </p>
      <p>
        This app tries to make it easy to focus on typing as opposed to being
        realistic about how text is read in real life. The normal and focused
        levels present all of the words at once with the current typing location
        highlighted. The zen focus level presents one word at a time.
      </p>
      <div className="static-content-title">Chart of Drills</div>
      <p>
        After you finish typing you will see the drill added as a rectangle at
        the right edge of a chart. High accuracy is represented by a nice bold
        green. Lower accuracy looks sad and washed out. Click on a bar to select
        a drill.
      </p>
      <div className="static-content-title">Key Presses</div>
      <p>
        The bottom chart shows the keys as you pressed them shown against time
        for the selected drill. Each rectangle represents one second. Errors are
        shown in red. Hover over the words or the key presses to see more
        details.
      </p>
      <p>
        Hopefully all of these visualizations will help you understand where
        your time went so you can focus your practice time. If not, perhaps it
        will be a good distraction while you rest up for the next drill!
      </p>
    </div>
  );
};
