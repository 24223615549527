import { DrillMeasurement } from './DrillMeasurement';
import { roundToValue } from '../util/math-utils';

export type GoalDTO = {
  wpm: number;
  time: number;
  accuracy: number;
};

export class Goal {
  private readonly goalWPM: number;
  private readonly goalTime: number;
  private readonly goalAccuracy: number;

  // goalTime is in seconds
  constructor(goalWPM: number, goalTime: number, goalAccuracy: number) {
    this.goalWPM = goalWPM;
    this.goalTime = goalTime;
    this.goalAccuracy = goalAccuracy;
  }

  public getGoalWPM() {
    return this.goalWPM;
  }

  public getGoalTimeInSeconds() {
    return this.goalTime;
  }

  public getGoalAccuracy() {
    return this.goalAccuracy;
  }

  public shouldCelebrate(measurement: DrillMeasurement) {
    return (
      roundToValue(measurement.getWPM(), 0.1) >=
        roundToValue(this.getGoalWPM(), 0.1) &&
      roundToValue(measurement.getAccuracy(), 0.1) >=
        roundToValue(this.getGoalAccuracy(), 0.1)
    );
  }

  // return a copy of this goal with the passed WPM
  public patchWPM(newWPM: number) {
    return new Goal(newWPM, this.goalTime, this.goalAccuracy);
  }

  public toDTO() {
    return {
      wpm: this.goalWPM,
      time: this.goalTime,
      accuracy: this.goalAccuracy,
    };
  }

  public static fromDTO(dto: GoalDTO): Goal {
    return new Goal(dto.wpm, dto.time, dto.accuracy);
  }
}
