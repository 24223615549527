const is_yapt = window.location.hostname.includes('yatp');

export function isYapt() {
  return is_yapt;
}

export function getAppName() {
  if (is_yapt) {
    return 'yatp';
  }

  return 'typingpractice';
}

export function getFullAppName() {
  if (is_yapt) {
    return 'Yet Another Typing Practice';
  }

  return 'Typing Practice';
}
