import { PatternGenerator } from './PatternGenerator';

export const regExpGenerator = new PatternGenerator(
  'Regular Expression',
  false,
  '^w.*e.*t$',
  '',
  '',
  /^\^\w*\.\*\w*\.\*\w*\$$/,
  4,
  5,
  true,
  true
);

export const leftHandPatternGenerator = new PatternGenerator(
  'Left Hand',
  true,
  '^[qwertasdfgzxcvb]*$',
  '',
  '',
  /^\^\[qwertasdfgzxcvb\]\*\$$/,
  4,
  5,
  true,
  false
);

export const rightHandPatternGenerator = new PatternGenerator(
  'Right Hand',
  true,
  '^[yuiophjklnm]*$',
  '',
  '',
  /^\^\[yuiophjklnm\]\*\$$/,
  4,
  5,
  true,
  true
);

export const leftEdgePatternGenerator = new PatternGenerator(
  'Left Edge',
  true,
  '[qzx]+',
  '',
  '',
  /^\^\[qwertasdfgzxcvb\]\*\$$/,
  4,
  5,
  true,
  false
);

export const rightEdgePatternGenerator = new PatternGenerator(
  'Right Edge',
  true,
  '[opl]+',
  '',
  '',
  /^\^\[qwertasdfgzxcvb\]\*\$$/,
  4,
  5,
  true,
  false
);

export const startsWithGenerator = new PatternGenerator(
  'Starts With',
  false,
  'ch',
  '^',
  '',
  /^\^\w*$/,
  4,
  5,
  true,
  true
);

export const containsGenerator = new PatternGenerator(
  'Contains',
  false,
  'ea',
  '',
  '',
  /^\w*$/,
  4,
  5,
  true,
  true
);

export const anyGenerator = new PatternGenerator(
  'Any Word',
  true,
  '',
  '',
  '',
  /^\w*$/,
  4,
  5,
  true,
  true
);

export const endsWithGenerator = new PatternGenerator(
  'Ends With',
  false,
  'ed',
  '',
  '$',
  /^\w*\$$/,
  4,
  5,
  true,
  true
);
