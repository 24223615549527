import { FunctionComponent } from 'react';

import { FocusLevel } from '../../../model/FocusLevel';
import './Selector.scss';
import { getKey } from '../../../util/enum-utils';

// it seems like Generics would help DRY this out,
// but is the juice worth the squeeze?

type FocusLevelSelectorProps = {
  selection: FocusLevel;
  onChanged: (newSelection: FocusLevel) => void;
};

export const FocusLevelSelector: FunctionComponent<FocusLevelSelectorProps> = ({
  selection,
  onChanged,
}) => {
  const valueDivs = Object.values(FocusLevel).map((value) => {
    const key = getKey(FocusLevel, value);

    const selectionClass =
      'selection ' + (value === selection ? 'selected' : '');

    return (
      <div
        key={'selection-' + key}
        className={selectionClass}
        onClick={() => onChanged(value as FocusLevel)}
      >
        {value}
      </div>
    );
  });

  return (
    <div className="Selector">
      <div className="label">Focus: </div>
      {valueDivs}
    </div>
  );
};
