// round the passed raw number to the nearest roundValue
export function roundToValue(raw: number, roundValue: number) {
  return Math.round(raw / roundValue) * roundValue;
}

// ceil the passed raw number to the nearest roundValue
export function ceilToValue(raw: number, ceilValue: number) {
  return Math.ceil(raw / ceilValue) * ceilValue;
}

export const clamp = (min: number, max: number, inputValue: number): number => {
  return Math.max(min, Math.min(max, inputValue));
};

const minOpacity = 0.15;
const opacityScale = createScale(85, 100, minOpacity, 1);
export const calculateOpacityFromAccuracy = (accuracy: number) => {
  return clamp(minOpacity, 1.0, opacityScale(accuracy));
};

export function createScale(
  rawMin: number,
  rawMax: number,
  outMin: number,
  outMax: number
) {
  const rawRange = rawMax - rawMin;
  const width = outMax - outMin;

  return (rawValue: number) => {
    return outMin + (width * (rawValue - rawMin)) / rawRange;
  };
}
