import React, { FunctionComponent } from 'react';
import './DrillDetailChart.scss';
import { DrillMeasurement } from '../../../model/DrillMeasurement';
import { Section } from '../../../model/Section';
import { Press } from '../../../model/Press';
import { useMeasure } from 'react-use';
import { makeRange } from '../../../util/range';

type DrillDetailChartProps = {
  drillMeasurement: DrillMeasurement | null;
  onSectionFocused: (sectionIndex: number) => void;
  focusedSectionIndex: number | null;
};

export const DrillDetailChart: FunctionComponent<DrillDetailChartProps> = ({
  drillMeasurement,
  onSectionFocused,
  focusedSectionIndex,
}) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const pixelsPerCharacter = 18;
  const rowHeightInPixels = 2 * pixelsPerCharacter;

  const charactersPerWord = 4;
  const universalGoalWPM = 80;
  const goalCharactersPerSecond = (universalGoalWPM * charactersPerWord) / 60;

  const effectiveWidth = width === 0 ? 100 : width;
  const charactersPerRow = effectiveWidth / pixelsPerCharacter;
  const secondsPerRow = Math.floor(charactersPerRow / goalCharactersPerSecond);

  if (!drillMeasurement || drillMeasurement.getSections().length < 1) {
    return null;
  }

  const startStamp = drillMeasurement.getSections()[0].getStartTimeStamp();

  const sectionFragments = drillMeasurement
    .getSections()
    .map((section: Section, sectionIndex) => {
      const pressSpans = section
        .getPressedList()
        .map((press: Press, pressIndex: number) => {
          const pressOffset = press.stamp - startStamp;
          const rowIndex = Math.floor(pressOffset / (secondsPerRow * 1000));
          const rowStartStamp = rowIndex * secondsPerRow * 1000;

          const color = press.error ? 'red' : 'black';
          const top = `${rowIndex * rowHeightInPixels + 7}px`;
          const leftRatio =
            (pressOffset - rowStartStamp) / (secondsPerRow * 1000);
          const left = `${leftRatio * 100}%`;

          const focusClass =
            sectionIndex === focusedSectionIndex ? ' focused' : '';

          const widthForHitBox =
            pressIndex < section.getPressedList().length - 1 ? '20px' : '10px';
          const style = {
            left,
            top,
            color: color,
            fontSize: `${pixelsPerCharacter}px`,
            width: widthForHitBox,
          };
          return (
            <span
              className={'press-detail' + focusClass}
              key={`${sectionIndex}_${pressIndex}`}
              style={style}
              onMouseEnter={() => onSectionFocused(sectionIndex)}
            >
              {press.key !== ' ' ? press.key : '_'}
            </span>
          );
        });

      return (
        <React.Fragment key={`section_fragment_${sectionIndex}`}>
          {pressSpans}
        </React.Fragment>
      );
    });

  const rowCount = Math.ceil(
    (drillMeasurement.getElapsedMinutes() * 60) / secondsPerRow
  );
  const rowDivs = makeRange(0, rowCount).map((rowIndex) => {
    const style = {
      top: `${rowIndex * rowHeightInPixels}px`,
      height: `${rowHeightInPixels}px`,
    };
    return (
      <div className="row-div" style={style} key={`row_${rowIndex}`}></div>
    );
  });

  const columnDivs = makeRange(0, secondsPerRow).map((columnIndex) => {
    const style = {
      left: `${(100 * columnIndex) / secondsPerRow}%`,
      width: `${100 / secondsPerRow}%`,
      height: `${rowHeightInPixels * rowCount}px`,
    };
    return (
      <div
        className="column-div"
        style={style}
        key={`column_${columnIndex}`}
      ></div>
    );
  });

  const chartStyle = {
    height: `${rowHeightInPixels * rowCount}px`,
  };

  return (
    <div ref={ref} className="DrillDetailChart">
      <div className="detail-chart" style={chartStyle}>
        {rowDivs}
        {columnDivs}
        {sectionFragments}
      </div>
      <div className="explanation">Each rectangle is one second wide</div>
    </div>
  );
};
