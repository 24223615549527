import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const Contact: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">Contact</div>
      <p>
        Please let me know what you like, what you dislike and what you need in
        the future.
      </p>

      <p>
        The best way to reach me is:{' '}
        <a href="mailto:ct@typingpractice.app">ct@typingpractice.app</a>
      </p>
    </div>
  );
};
