import React, { FunctionComponent } from 'react';
import './DrillSummary.scss';
import { DrillMeasurement } from '../../../model/DrillMeasurement';
import pluralize from 'pluralize';
import { calculateOpacityFromAccuracy } from '../../../util/math-utils';

/*
Component that displays a text summary of a typing drill
 */

type DrillSummaryProps = {
  drillMeasurement: DrillMeasurement | null;
};

export const DrillSummary: FunctionComponent<DrillSummaryProps> = ({
  drillMeasurement,
}) => {
  if (!drillMeasurement) {
    return null;
  }

  const drillDate = new Date(drillMeasurement.getUnixStamp());

  const accuracyStyle = {
    opacity: calculateOpacityFromAccuracy(drillMeasurement.getAccuracy()),
  };

  const shouldCelebrate = drillMeasurement
    .getGoal()
    .shouldCelebrate(drillMeasurement);

  return (
    <div className="DrillSummary">
      <div className="main">
        {(
          drillMeasurement.getTotalWords() /
          drillMeasurement.getElapsedMinutes()
        ).toFixed(1)}
        &nbsp;WPM
      </div>
      {shouldCelebrate && <div className="main celebration">&#127881;</div>}
      <div className="details">
        <div>
          {drillDate.toLocaleDateString()} at {drillDate.toLocaleTimeString()}
        </div>
        <div>
          {drillMeasurement.getTotalErrors()}{' '}
          {pluralize('error', drillMeasurement.getTotalErrors())} in{' '}
          {drillMeasurement.getTotalWords()} words over{' '}
          {(drillMeasurement.getElapsedMinutes() * 60).toFixed(1)} seconds
        </div>
        <div>
          {drillMeasurement.getCPS().toFixed(1)}
          &nbsp;characters per second
        </div>
        <div>
          {(drillMeasurement.getReadingMinutes() * 60).toFixed(1)} seconds spent
          between the words ({' '}
          {(
            (100 * drillMeasurement.getReadingMinutes()) /
            drillMeasurement.getElapsedMinutes()
          ).toFixed(0)}{' '}
          % )
        </div>
        <div>Goal of {drillMeasurement.getGoal().getGoalWPM()} WPM</div>
      </div>
      {shouldCelebrate && <div className="main celebration">&#127881;</div>}
      <div className="main accuracy" style={accuracyStyle}>
        {drillMeasurement.getAccuracy().toFixed(1)}%
      </div>
    </div>
  );
};
