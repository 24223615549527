import { GeneratorEditor } from '../GeneratorEditor/GeneratorEditor';
import { GoalEditor } from '../GoalEditor/GoalEditor';

import { FunctionComponent } from 'react';
import { SectionListGenerator } from '../../../model/SectionListGenerator';
import { Goal } from '../../../model/Goal';
import { FocusLevel } from '../../../model/FocusLevel';
import { FocusLevelSelector } from '../Selectors/FocusLevelSelector';

import './FullConfiguration.scss';

type FullConfigurationProps = {
  generator: SectionListGenerator;
  generators: SectionListGenerator[];
  onGeneratorChanged: (newGenerator: SectionListGenerator) => void;
  goal: Goal;
  onGoalChanged: (newGoal: Goal) => void;
  focusLevel: FocusLevel;
  onFocusLevelChanged: (newFocusLevel: FocusLevel) => void;
  onShowSettingsChanged: () => void;
};

export const FullConfiguration: FunctionComponent<FullConfigurationProps> = ({
  generator,
  generators,
  onGeneratorChanged,
  goal,
  onGoalChanged,
  focusLevel,
  onFocusLevelChanged,
  onShowSettingsChanged,
}) => {
  return (
    <div className="FullConfiguration">
      <FocusLevelSelector
        selection={focusLevel}
        onChanged={onFocusLevelChanged}
      />
      <GeneratorEditor
        generator={generator}
        generators={generators}
        onGeneratorChanged={onGeneratorChanged}
      />
      <GoalEditor goal={goal} onGoalChanged={onGoalChanged} />
      <button onClick={onShowSettingsChanged}>Close</button>
    </div>
  );
};
