import { FunctionComponent } from 'react';

import { CircleLoader } from 'react-spinners';

import './LoadingIndicator.scss';

export const LoadingIndicator: FunctionComponent = () => {
  return (
    <div className="LoadingIndicator">
      <div className="loading-label">LOADING</div>
      <CircleLoader color="#0000ff" size={100} aria-label="Loading Spinner" />
    </div>
  );
};
