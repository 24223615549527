import { FunctionComponent } from 'react';
import './TopNavigation.scss';
import { ContentLink } from './ContentLink';
import { ActionLink } from './ActionLink';
import { getAppName } from '../../../util/app-name';
import { FirebaseUser } from '../../../model/FirebaseUser';

type TopNavigationProps = {
  currentModal: string;
  setCurrentModal: (modalName: string) => void;
  user: FirebaseUser;
  onSignOut: () => void;
  onSignIn: () => void;
  onShowSettingsChanged: () => void;
};

export const TopNavigation: FunctionComponent<TopNavigationProps> = ({
  currentModal,
  setCurrentModal,
  user,
  onSignOut,
  onSignIn,
  onShowSettingsChanged,
}: TopNavigationProps) => {
  return (
    <div className="TopNavigation">
      <div className="left-titles">
        <span className="page-title">{getAppName()}</span>
        <span className="page-sub-title">.app</span>
      </div>
      <div className="right-links">
        <ContentLink
          text="Privacy"
          currentModal={currentModal}
          setCurrentModal={setCurrentModal}
        ></ContentLink>
        <ContentLink
          text="About"
          currentModal={currentModal}
          setCurrentModal={setCurrentModal}
        />
        <ContentLink
          text="Contact"
          currentModal={currentModal}
          setCurrentModal={setCurrentModal}
        />
        <div className="user-info">
          <span>{user.displayName}</span>
          {!user.guest && <ActionLink onClick={onSignOut} text="Sign Out" />}
          {user.guest && (
            <ActionLink onClick={onSignIn} text="Login / Register" />
          )}
          <span className="settings-toggle" onClick={onShowSettingsChanged}>
            &#x2699;
          </span>
        </div>
      </div>
    </div>
  );
};
