import React, { FunctionComponent } from 'react';
import './CustomGeneratorEditor.scss';
import { CustomGenerator } from './CustomGenerator';

type CustomGeneratorEditorProps = {
  generator: CustomGenerator;
  onGeneratorChanged: (generator: CustomGenerator) => void;
};

export const CustomGeneratorEditor: FunctionComponent<
  CustomGeneratorEditorProps
> = ({ generator, onGeneratorChanged }) => {
  const onChangeRawText = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const newValue = event.currentTarget.value;
    const newGenerator = generator.patchRawText(newValue);
    onGeneratorChanged(newGenerator);
  };

  return (
    <div className="CustomGeneratorEditor">
      <div className="words">
        <div className="label">Custom Words</div>
        <textarea
          autoFocus={true}
          value={generator.getRawText()}
          onChange={onChangeRawText}
        />
      </div>
    </div>
  );
};
