import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const SaveError: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">Error</div>
      <p>
        There was an error while saving your data. You might try again in a bit
        or refresh this page.
      </p>
    </div>
  );
};
