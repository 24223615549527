import { getAnalytics, logEvent } from 'firebase/analytics';
import firebase from 'firebase/compat/app';
import 'firebaseui/dist/firebaseui.css';
import { auth } from 'firebaseui';

import { v4 as uuidv4 } from 'uuid';

import { FirebaseUser } from '../model/FirebaseUser';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBNIXL81zB79znH5r24HDixiIdHb9o-rGQ',
  authDomain: 'yatp-app.firebaseapp.com',
  projectId: 'yatp-app',
  storageBucket: 'yatp-app.appspot.com',
  messagingSenderId: '609829372104',
  appId: '1:609829372104:web:082e99dd0fb7c529ad25fd',
  measurementId: 'G-4KMS664Q3B',
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firebaseAuthUI = new auth.AuthUI(firebase.auth());

export const onAuthStateChanged = (
  cb: firebase.Observer<any, Error> | ((a: firebase.User | null) => any)
) => {
  firebase.auth().onAuthStateChanged(cb);
};

export const logAnalyticsEvent = (eventName: string) => {
  logEvent(analytics, eventName);
};

export const signIn = () => {
  firebaseAuthUI.start('#firebaseui-auth-container', {
    signInSuccessUrl: window.location.href,
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
  });
};

export const signOut = () => {
  firebase.auth().signOut();
};

const DEFAULT_USER_STORAGE_KEY = 'guestUser';
export const createDefaultUser = () => {
  const guestUserData = localStorage.getItem(DEFAULT_USER_STORAGE_KEY);
  if (guestUserData) {
    return JSON.parse(guestUserData);
  }

  const defaultUser: FirebaseUser = {
    uid: uuidv4(),
    email: '',
    displayName: 'Guest',
    guest: true,
  };

  localStorage.setItem(DEFAULT_USER_STORAGE_KEY, JSON.stringify(defaultUser));
  return defaultUser;
};
