export const getKey = (e: any, value: string) => {
  const keys = Object.keys(e);
  const values = Object.values(e);
  const index = values.indexOf(value);
  return keys[index];
};

export const getValue = (e: any, key: string) => {
  const keys = Object.keys(e);
  const values = Object.values(e);
  const index = keys.indexOf(key);
  return values[index];
};
