import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const Welcome2: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">Recent Drills</div>
      <p>
        After you finish typing you will see the drill added as a rectangle at
        the right edge of a chart. Below that is a summary of the drill and
        details for one word at a time. Hover to select a different drill or a
        different word.
      </p>
      <div className="static-content-title">Key Presses</div>
      <p>
        The bottom chart shows the keys as you pressed them shown against time
        for the selected drill. Each rectangle represents one second. Errors are
        shown in red. Hover over the words or the key presses to see more
        details.
      </p>
      <p>
        Hopefully all of these visualizations will help you understand where
        your time went so you can focus your practice time. If not, perhaps it
        will be a good distraction while you rest up for the next drill!
      </p>
    </div>
  );
};
