import React, { FunctionComponent, useEffect, useState } from 'react';
import {} from 'uuid';

import './App.scss';
import { TopNavigation } from './components/app/Navigation/TopNavigation';
import { ModalContent } from './components/app/ModalContent/ModalContent';
import { BottomNavigation } from './components/app/Navigation/BottomNavigation';
import { SimpleTypingGame } from './components/TypingGames/SimpleTypingGame/SimpleTypingGame';
import { SectionListGenerator } from './model/SectionListGenerator';

import { Section } from './model/Section';
import { Goal } from './model/Goal';
import { CustomGenerator } from './components/WordGenerators/CustomGenerator/CustomGenerator';
import { Review } from './components/review/Review';
import {
  DrillMeasurement,
  DrillMeasurementDTO,
} from './model/DrillMeasurement';

import {
  loadDrillMeasurements,
  loadGoal,
  saveDrillMeasurements,
  saveGoal,
} from './util/persistence-facade';
import { FirebaseUser } from './model/FirebaseUser';
import { uniqueMeasurements } from './util/unique-measurements';
import { FocusLevel } from './model/FocusLevel';
import { Confetti } from './components/review/Confetti/Confetti';
import { GoalSummary } from './components/review/GoalSummary/GoalSummary';
import {
  anyGenerator,
  containsGenerator,
  endsWithGenerator,
  leftHandPatternGenerator,
  leftEdgePatternGenerator,
  rightEdgePatternGenerator,
  regExpGenerator,
  rightHandPatternGenerator,
  startsWithGenerator,
} from './components/WordGenerators/PatternGenerator/pattern-generators';
import { FullConfiguration } from './components/configuration/FullConfiguration/FullConfiguration';
import {
  createDefaultUser,
  logAnalyticsEvent,
  onAuthStateChanged,
  signIn,
  signOut,
} from './util/firebase-util';
import { roundToValue } from './util/math-utils';
import { useTimeout } from 'usehooks-ts';
import { LoadingIndicator } from './components/app/LoadingIndicator/LoadingIndicator';

const defaultUser = createDefaultUser();
const defaultGoal = new Goal(50, 30, 95);

const customGenerator = new CustomGenerator('hi there', false);

const generators: SectionListGenerator[] = [
  anyGenerator,
  leftHandPatternGenerator,
  rightHandPatternGenerator,
  leftEdgePatternGenerator,
  rightEdgePatternGenerator,
  startsWithGenerator,
  containsGenerator,
  endsWithGenerator,
  regExpGenerator,
  customGenerator,
];

const defaultGenerator = anyGenerator;

const App: FunctionComponent = () => {
  const [firstLoadStatus, setFirstLoadStatus] = useState<
    'checking' | 'needed' | 'complete' | 'error'
  >('checking');
  const [celebration, setCelebration] = useState<boolean>(false);
  const [user, setUser] = useState<FirebaseUser>(defaultUser);
  const [currentModal, setCurrentModal] = useState<string>('');
  const [sections, setSections] = useState<Section[]>(
    defaultGenerator.getSections(defaultGoal)
  );
  const [selectedMeasurement, setSelectedMeasurement] =
    useState<DrillMeasurement | null>(null);
  const [shownMeasurement, setShownMeasurement] =
    useState<DrillMeasurement | null>(null);
  const [measurements, setMeasurements] = useState<DrillMeasurement[]>([]);
  const [generator, setGenerator] =
    useState<SectionListGenerator>(anyGenerator);
  const [goal, setGoal] = useState<Goal>(defaultGoal);
  const [typingState, setTypingState] = useState<'type' | 'review'>('review');
  const [focusLevel, setFocusLevel] = useState<FocusLevel>(FocusLevel.focused);
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const doSetMeasurements = (newMeasurements: DrillMeasurement[]) => {
    const measurementSet = uniqueMeasurements(newMeasurements);
    setMeasurements(measurementSet);
  };

  const dirtyMeasurements = () => {
    return measurements.filter((measurement) => {
      return measurement.isDirty();
    });
  };

  const doSetGoal = (newGoal: Goal) => {
    setGoal(newGoal);

    saveGoal(user.uid, newGoal)
      .then((response: Response) => {
        if (!response.ok) {
          setCurrentModal('SaveError');
        }
      })
      .catch((e) => {
        console.error('e: ', e);
        setCurrentModal('SaveError');
      });
  };

  const doAutosave = () => {
    const dirtySet = dirtyMeasurements();
    if (dirtySet.length === 0 || !user) {
      return;
    }

    saveDrillMeasurements(user.uid, uniqueMeasurements(dirtySet))
      .then((response: Response) => {
        if (response.ok) {
          dirtySet.forEach((measurement: DrillMeasurement) => {
            measurement.setDirty(false);
          });
          doSetMeasurements([...measurements]);
        } else {
          setCurrentModal('SaveError');
        }
      })
      .catch((e) => {
        console.error('e: ', e);
        setCurrentModal('SaveError');
      });
  };

  useEffect(() => {
    if (firstLoadStatus === 'needed') {
      loadGoal(user.uid)
        .then((response: Response) => response.text())
        .then((body) => {
          if (body.length === 0) {
            doSetGoal(goal);
            return;
          }

          const goalDTO = JSON.parse(body);
          const newGoal = Goal.fromDTO(goalDTO);

          setGoal(newGoal);
        })
        .catch((e) => {
          setCurrentModal('LoadError');
          setFirstLoadStatus('error');
          console.error('e', e);
        });

      loadDrillMeasurements(user.uid)
        .then((response: Response) => response.text())
        .then((body) => {
          if (body.length === 0) {
            return;
          }

          const newMeasurementsDTOs = JSON.parse(body);
          const newMeasurements = newMeasurementsDTOs.map(
            (dto: DrillMeasurementDTO) => {
              return DrillMeasurement.fromDTO(dto);
            }
          );

          const consolidatedMeasurements = [...newMeasurements];

          doSetMeasurements(consolidatedMeasurements);

          const lastMeasurement =
            consolidatedMeasurements.length > 0
              ? consolidatedMeasurements[consolidatedMeasurements.length - 1]
              : null;
          setSelectedMeasurement(lastMeasurement);
          setShownMeasurement(lastMeasurement);

          if (lastMeasurement) {
            setTypingState('review');
          } else {
            setTypingState('type');
          }
          setFirstLoadStatus('complete');
        })
        .catch((e) => {
          setCurrentModal('LoadError');
          setFirstLoadStatus('error');
          console.error('e', e);
        });
    }

    doAutosave();
    // the dependency list has to be the things that matter and only the things that matter
    // eslint-disable-next-line
  }, [firstLoadStatus, user]);

  useEffect(() => {
    if (measurements.length > 0 && user !== null) {
      doAutosave();
    }
    // the dependency list has to be the things that matter and only the things that matter
    // eslint-disable-next-line
  }, [measurements, user]);

  useEffect(() => {
    onAuthStateChanged((authenticatedUser: any) => {
      if (!authenticatedUser) {
        setUser(defaultUser);
        setMeasurements([]);
        setFirstLoadStatus('needed');
        return;
      }

      const newUser: FirebaseUser = {
        uid: authenticatedUser.uid,
        displayName: authenticatedUser.displayName,
        email: authenticatedUser.email,
        guest: false,
      };
      setUser(newUser);
      setFirstLoadStatus('needed');
    });
  }, []);

  useTimeout(() => {
    if (firstLoadStatus !== 'complete') {
      setFirstLoadStatus('needed');
    }
  }, 500);

  const onShowSettingsChanged = () => {
    setShowSettings(!showSettings);
    logAnalyticsEvent('toggle-settings');
  };

  const onGoalChanged = (newGoal: Goal) => {
    doSetGoal(newGoal);

    if (
      newGoal.getGoalWPM() !== goal.getGoalWPM() ||
      newGoal.getGoalTimeInSeconds() !== goal.getGoalTimeInSeconds()
    ) {
      const newSections = generator.getSections(newGoal);
      setSections(newSections);
    }

    logAnalyticsEvent('goal-changed');
  };

  const onGeneratorChanged = (newGenerator: SectionListGenerator) => {
    setGenerator(newGenerator);

    const matchingIndex = generators.findIndex((generator) => {
      return generator.getName() === newGenerator.getName();
    });

    if (matchingIndex >= 0) {
      generators[matchingIndex] = newGenerator;
    }

    const newSections = newGenerator.getSections(goal);
    setSections(newSections);

    logAnalyticsEvent('generator-changed');
  };

  const onFocusLevelChanged = (newLevel: FocusLevel) => {
    setFocusLevel(newLevel);
    logAnalyticsEvent('focus-level-set-' + newLevel);
  };

  const adaptGoal = (newWPM: number) => {
    return goal.patchWPM(roundToValue(newWPM, 0.5));
  };

  const onDrillFinished = () => {
    const actualMeasurement = new DrillMeasurement(sections, goal, true);

    const newMeasurement =
      measurements.length === 0
        ? new DrillMeasurement(
            sections,
            goal.patchWPM(roundToValue(actualMeasurement.getWPM() + 1, 0.5)),
            true
          )
        : actualMeasurement;
    setTypingState('review');

    doSetMeasurements([...measurements, newMeasurement]);
    setSelectedMeasurement(newMeasurement);
    setShownMeasurement(newMeasurement);

    const newCelebration =
      newMeasurement && goal.shouldCelebrate(newMeasurement);
    setCelebration(newCelebration);

    if (measurements.length === 0) {
      const newGoal = adaptGoal(newMeasurement.getGoal().getGoalWPM());
      doSetGoal(newGoal);
    } else if (newCelebration) {
      const mixWPM = (goal.getGoalWPM() + newMeasurement.getWPM()) / 2;
      const newGoal = adaptGoal(mixWPM + 1);
      doSetGoal(newGoal);
      const newSections = generator.getSections(newGoal);
      setSections(newSections);
      logAnalyticsEvent('increased-guided-goal');
    }

    logAnalyticsEvent('drill-finished');
  };

  const onEscape = () => {
    if (measurements.length < 1) {
      return;
    }

    setTypingState('review');
    logAnalyticsEvent('escape');
  };

  const nextGuidedDrill = () => {
    setTypingState('type');
    const newSections = sections.map((section) => {
      return new Section(section.getTarget());
    });
    setSections(newSections);
    logAnalyticsEvent('next-guided-drill');
  };

  const onSelectMeasurement = (measurement: DrillMeasurement | null) => {
    setSelectedMeasurement(measurement);
    setShownMeasurement(measurement);
    logAnalyticsEvent('select-measurement');
  };

  const onShowMeasurement = (measurement: DrillMeasurement | null) => {
    if (!measurement && selectedMeasurement) {
      setShownMeasurement(selectedMeasurement);
    } else {
      setShownMeasurement(measurement);
    }
  };

  const onSignIn = () => {
    signIn();
  };

  const onSignOut = () => {
    signOut();
    setUser(defaultUser);
    doSetMeasurements([]);
    setSelectedMeasurement(null);
    setShownMeasurement(null);
    setFirstLoadStatus('needed');
  };

  const visibleMeasurements = measurements
    .sort((left: DrillMeasurement, right: DrillMeasurement) => {
      return right.getUnixStamp() - left.getUnixStamp();
    })
    .slice(0, 75);

  return (
    <div className="App">
      {currentModal !== '' && (
        <ModalContent
          title={currentModal}
          onClose={() => setCurrentModal('')}
        />
      )}
      <TopNavigation
        currentModal={currentModal}
        setCurrentModal={setCurrentModal}
        user={user}
        onSignOut={onSignOut}
        onSignIn={onSignIn}
        onShowSettingsChanged={onShowSettingsChanged}
      />
      <div className="main-content">
        {user.guest && <div id="firebaseui-auth-container"></div>}

        {firstLoadStatus === 'needed' && <LoadingIndicator />}
        {celebration && <Confetti onDone={() => setCelebration(false)} />}

        {showSettings && typingState === 'review' && (
          <>
            <FullConfiguration
              generator={generator}
              generators={generators}
              onGeneratorChanged={onGeneratorChanged}
              goal={goal}
              onGoalChanged={onGoalChanged}
              focusLevel={focusLevel}
              onFocusLevelChanged={onFocusLevelChanged}
              onShowSettingsChanged={onShowSettingsChanged}
            />
          </>
        )}

        {firstLoadStatus === 'complete' && typingState === 'review' && (
          <div className="guided">
            <GoalSummary goal={goal} />
            <button onClick={() => nextGuidedDrill()}>START</button>
          </div>
        )}

        {typingState === 'type' && (
          <SimpleTypingGame
            sections={sections}
            onDrillFinished={onDrillFinished}
            onEscape={onEscape}
            focusLevel={focusLevel}
            allowEscape={measurements.length > 0}
          />
        )}
        {typingState === 'review' && (
          <>
            <Review
              measurements={visibleMeasurements}
              goal={goal}
              selectedMeasurement={shownMeasurement}
              onSelectMeasurement={onSelectMeasurement}
              onShowMeasurement={onShowMeasurement}
            />
          </>
        )}
      </div>
      <BottomNavigation
        currentModal={currentModal}
        setCurrentModal={setCurrentModal}
      />
    </div>
  );
};

export default App;
