import { FunctionComponent, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import './Confetti.scss';

const PERIOD_MILLISECONDS = 10;
const DURATION_SECONDS = 4;
const NUM_TICKS = (DURATION_SECONDS * 1000) / PERIOD_MILLISECONDS;

const MAX_SKEW = 30;
const MAX_ROT = 90;

const PIECE_COUNT = 100;

type ConfettiProps = {
  onDone: () => void;
};

export const Confetti: FunctionComponent<ConfettiProps> = ({ onDone }) => {
  const [tick, setTick] = useState<number>(0);
  useInterval(() => {
    const newTick = tick + 1;
    if (newTick > NUM_TICKS) {
      setTick(0);
      onDone();
    }
    setTick(tick + 1);
  }, PERIOD_MILLISECONDS);

  const pieces = [];

  const time = (tick * PERIOD_MILLISECONDS) / 1000;
  for (let ctr = 0; ctr < PIECE_COUNT; ctr++) {
    const x =
      1.3 * (ctr % 7) * time +
      0.6 * (ctr % 13) * time +
      0.4 * (ctr % 21) * time -
      1.1 * time * time;
    const y =
      2.1 * (ctr % 9) * time +
      2.7 * (ctr % 15) * time +
      2.1 * (ctr % 25) * time -
      6.7 * time * time;
    const opacity = 1.25 - time / DURATION_SECONDS;

    const skew = (((time + ctr) * MAX_SKEW) / DURATION_SECONDS) % MAX_SKEW;
    const rot = (((time + ctr) * MAX_ROT) / DURATION_SECONDS) % MAX_ROT;

    const red = (80 + ctr * 30) % 255;
    const green = (ctr * 20) % 255;
    const blue = (80 + ctr * 45) % 255;
    const color = `rgba(${red}, ${green}, ${blue}, ${opacity})`;

    const style = {
      top: `${100 - y}%`,
      left: `${x}%`,
      transform: `rotate(${rot}deg) skew(${skew}deg, ${skew}deg)`,
      backgroundColor: `${color}`,
    };

    const piece = <div className="piece" key={ctr} style={style}></div>;
    pieces.push(piece);
  }

  return tick < NUM_TICKS || true ? (
    <div className="Confetti">{pieces}</div>
  ) : null;
};
