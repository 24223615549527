import { FunctionComponent } from 'react';
import { Goal } from '../../../model/Goal';

import './GoalSummary.scss';

type GoalSummaryProps = {
  goal: Goal;
};

export const GoalSummary: FunctionComponent<GoalSummaryProps> = ({ goal }) => {
  return (
    <div className="GoalSummary">
      <div className="goal-sentence">
        <span className="label">Goal:</span>
        <span className="value">{goal.getGoalWPM()}</span>
        <span>&nbsp;WPM for&nbsp;</span>
        <span className="value">{goal.getGoalTimeInSeconds()}</span>
        <span>&nbsp; seconds with at least&nbsp;</span>
        <span className="value">{goal.getGoalAccuracy()}% </span>
        <span>accuracy</span>
      </div>
    </div>
  );
};
