import { ChangeEvent, FunctionComponent, FormEvent } from 'react';
import { Goal } from '../../../model/Goal';

import './GoalEditor.scss';

type GoalEditorProps = {
  onGoalChanged: (goal: Goal) => void;
  goal: Goal;
};

export const GoalEditor: FunctionComponent<GoalEditorProps> = ({
  goal,
  onGoalChanged,
}) => {
  const onChangeGoalWPM = (event: FormEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.currentTarget.value);
    onGoalChanged(
      new Goal(newValue, goal.getGoalTimeInSeconds(), goal.getGoalAccuracy())
    );
  };

  const onSelectGoalAccuracy = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = parseInt(event.target.value, 10);
    onGoalChanged(
      new Goal(goal.getGoalWPM(), goal.getGoalTimeInSeconds(), newValue)
    );
  };

  const onSelectGoalTime = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = parseInt(event.target.value, 10);
    onGoalChanged(
      new Goal(goal.getGoalWPM(), newValue, goal.getGoalAccuracy())
    );
  };

  const wpmValues = [];
  for (let value = 10; value <= 200; value += 10) {
    wpmValues.push(value);
  }

  const timeOptions = [
    <option key="key_30" value={30}>
      30 seconds
    </option>,
    <option key="key_60" value={60}>
      1 minute
    </option>,
    <option key="key_120" value={120}>
      2 minutes
    </option>,
  ];

  const accuracyOptions = [95, 96, 97, 98, 99, 100].map((value) => {
    return (
      <option key={'key_' + value} value={value}>
        {'' + value}
      </option>
    );
  });

  return (
    <div className="GoalEditor">
      <div className="label">Goal</div>
      <div className="goal-sentence">
        <input
          className="wpm-input"
          type="number"
          min="1"
          onChange={onChangeGoalWPM}
          value={goal.getGoalWPM()}
        />
        <span>&nbsp;WPM for&nbsp;</span>
        <select
          className="goal time"
          value={goal.getGoalTimeInSeconds()}
          onChange={onSelectGoalTime}
        >
          {timeOptions}
        </select>
        <span>&nbsp;with at least&nbsp;</span>
        <select
          className="goal accuracy"
          value={goal.getGoalAccuracy()}
          onChange={onSelectGoalAccuracy}
        >
          {accuracyOptions}
        </select>
        <span>&nbsp;% accuracy</span>
      </div>
    </div>
  );
};
