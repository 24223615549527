import { Section, SectionDTO } from './Section';
import { Goal, GoalDTO } from './Goal';

const MINUTE_IN_MILLISECONDS = 60000;

/* DTO for DrillMeasurement */
export type DrillMeasurementDTO = {
  sectionDTOs: SectionDTO[];
  goalDTO: GoalDTO;
};

/*
Information about the typist's performance in the drill. All the information is calculated
from the sections.
 */
export class DrillMeasurement {
  private readonly totalTypingMilliseconds: number;
  private readonly totalElapsedMilliseconds: number;
  private readonly totalWords: number;
  private readonly totalErrors: number;
  private readonly maxWordsPerMinute: number;
  private readonly minWordsPerMinute: number;
  private readonly charactersPerSecond: number;
  private readonly sections: Section[];
  private readonly goal: Goal;
  private dirty: boolean;

  constructor(sections: Section[], goal: Goal, dirty: boolean) {
    this.sections = sections;
    this.goal = goal;
    this.dirty = dirty;

    this.totalElapsedMilliseconds =
      sections[sections.length - 1].getLastTimeStamp() -
      sections[0].getStartTimeStamp();

    this.totalWords = sections.reduce(
      (accumulator, section) => accumulator + section.getWordCount(),
      0
    );

    this.totalTypingMilliseconds = sections.reduce(
      (accumulator: number, section) =>
        accumulator +
        (section.getLastTimeStamp() - section.getStartTimeStamp()),
      0
    );

    this.totalErrors = sections.reduce((accumultator: number, section) => {
      const errorContribution = section.getErrorCount() > 0 ? 1 : 0;
      return accumultator + errorContribution;
    }, 0);

    const sectionWPMs: number[] = sections.map((section) => {
      return section.calculateWPM();
    });
    this.maxWordsPerMinute = Math.max(...sectionWPMs);
    this.minWordsPerMinute = Math.min(...sectionWPMs);

    const totalCharacters = sections.reduce(
      (accumulator, section) => (accumulator += section.getTarget().length),
      0
    );

    this.charactersPerSecond =
      totalCharacters / (this.totalElapsedMilliseconds / 1000);
  }

  public getTotalWords() {
    return this.totalWords;
  }

  public getTotalErrors() {
    return this.totalErrors;
  }

  public getElapsedMinutes() {
    return this.totalElapsedMilliseconds / MINUTE_IN_MILLISECONDS;
  }

  public getTypingMinutes() {
    return this.totalTypingMilliseconds / MINUTE_IN_MILLISECONDS;
  }

  public getReadingMinutes() {
    return (
      (this.totalElapsedMilliseconds - this.totalTypingMilliseconds) /
      MINUTE_IN_MILLISECONDS
    );
  }

  // error free words as a percentage of the total words
  public getAccuracy() {
    return (100 * (this.totalWords - this.totalErrors)) / this.totalWords;
  }

  public getWPM() {
    return this.totalWords / this.getElapsedMinutes();
  }

  public getCPS() {
    return this.charactersPerSecond;
  }

  // UNIX timestamp of when the measurement was recorded
  public getUnixStamp() {
    if (this.sections.length < 1) {
      return 0;
    }
    return this.sections[0].getUnixStart();
  }

  public getSections() {
    return this.sections;
  }

  public getMaxWordsPerMinute() {
    return this.maxWordsPerMinute;
  }

  public getMinWordsPerMinute() {
    return this.minWordsPerMinute;
  }

  public getGoal() {
    return this.goal;
  }

  public isDirty() {
    return this.dirty;
  }

  public setDirty(dirty: boolean) {
    this.dirty = dirty;
  }

  public toDTO(): DrillMeasurementDTO {
    return {
      sectionDTOs: this.getSections().map((section) => section.toDTO()),
      goalDTO: this.goal.toDTO(),
    };
  }

  public static fromDTO(dto: DrillMeasurementDTO): DrillMeasurement {
    const newSections: Section[] = dto.sectionDTOs.map((sectionDTO) => {
      return Section.fromDTO(sectionDTO);
    });

    const newGoal = dto.goalDTO
      ? Goal.fromDTO(dto.goalDTO)
      : new Goal(50, 30, 95);
    return new DrillMeasurement(newSections, newGoal, false);
  }
}
