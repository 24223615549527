import React, { FunctionComponent } from 'react';
import './SectionSummary.scss';
import { DrillMeasurement } from '../../../model/DrillMeasurement';

/*
Component that displays a text summary of a typing drill
 */

type SectionSummaryProps = {
  drillMeasurement: DrillMeasurement | null;
  focusedSectionIndex: number | null;
};

export const SectionSummary: FunctionComponent<SectionSummaryProps> = ({
  drillMeasurement,
  focusedSectionIndex,
}) => {
  if (!drillMeasurement || focusedSectionIndex === null) {
    return null;
  }

  const focusedSection = drillMeasurement.getSections()[focusedSectionIndex];

  if (!focusedSection) {
    return null;
  }

  const color = focusedSection.getErrorCount() > 0 ? 'red' : 'black';
  const style = {
    color: color,
  };

  const colorCodedPresses = focusedSection
    .getPressedList()
    .map((press, index) => {
      const color = press.error ? 'red' : 'black';
      const style = {
        color: color,
      };

      return (
        <span className="color-coded-press" key={index} style={style}>
          {press.key !== ' ' ? press.key : '_'}
        </span>
      );
    });

  return (
    <div className="SectionSummary">
      <>
        <div className="main">
          <div style={style} className="section-target">
            {focusedSection.getTarget()}
          </div>
        </div>
        <div className="details">
          <div title="Words per Minute">
            {focusedSection.calculateWPM().toFixed(0)} WPM
          </div>
          <div title="Characters per Second">
            {focusedSection.calculateCPS().toFixed(0)} CPS
          </div>
          <div>{focusedSection.getErrorCount()} Errors</div>
        </div>
        {focusedSection.getErrorCount() > 0 && (
          <div className="main">{colorCodedPresses}</div>
        )}
      </>
    </div>
  );
};
