import { FunctionComponent } from 'react';
import './ModalContent.scss';
import { About } from '../StaticContent/About';
import { Privacy } from '../StaticContent/Privacy';
import { SaveError } from '../StaticContent/SaveError';
import { LoadError } from '../StaticContent/LoadError';
import { Contact } from '../StaticContent/Contact';
import { Welcome1 } from '../StaticContent/Welcome1';
import { Welcome2 } from '../StaticContent/Welcome2';

type ModalContentProps = {
  title: string;
  onClose: () => void;
};
export const ModalContent: FunctionComponent<ModalContentProps> = ({
  title,
  onClose,
}: ModalContentProps) => {
  const style = title === 'Welcome1' ? { top: '330px' } : {};
  return (
    <div className="ModalContent" style={style} onClick={onClose}>
      <div className="modal-content-top">
        <div className="modal-content-closer">X</div>
      </div>
      <div className="modal-content-body">{title === 'About' && <About />}</div>
      <div className="modal-content-body">
        {title === 'Privacy' && <Privacy />}
        {title === 'SaveError' && <SaveError />}
        {title === 'LoadError' && <LoadError />}
        {title === 'Contact' && <Contact />}
        {title === 'Welcome1' && <Welcome1 />}
        {title === 'Welcome2' && <Welcome2 />}
      </div>
    </div>
  );
};
