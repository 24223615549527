import { SectionListGenerator } from '../../../model/SectionListGenerator';
import { Goal } from '../../../model/Goal';
import { Section } from '../../../model/Section';
import { shuffle } from '../../../util/shuffle';

export class CustomGenerator implements SectionListGenerator {
  private rawText: string;
  private scramble: boolean;

  constructor(rawText: string, scramble: boolean) {
    this.rawText = rawText;
    this.scramble = scramble;
  }

  public getName() {
    return 'Custom Words';
  }

  public getRawText() {
    return this.rawText;
  }

  public getScramble() {
    return this.scramble;
  }

  public isScrambleSupported() {
    return true;
  }

  public hasSections() {
    return this.rawText.trim().split(' ').length > 0;
  }

  getSections(goal: Goal): Section[] {
    const goalWordCount =
      (goal.getGoalWPM() / 60) * goal.getGoalTimeInSeconds();
    const words = this.rawText
      .trim()
      .split(' ')
      .map((raw) => raw.trim());
    const repeat = Math.ceil(goalWordCount / words.length);
    const sectionWords: string[] = [];
    for (let ctr = 0; ctr < repeat; ctr++) {
      words.forEach((word) => {
        if (sectionWords.length < goalWordCount) {
          sectionWords.push(word);
        }
      });
    }
    const shuffled = this.scramble ? shuffle(sectionWords) : sectionWords;
    return Section.getSections(shuffled);
  }

  patchRawText(text: string) {
    return new CustomGenerator(text, this.getScramble());
  }

  patchScramble(scramble: boolean) {
    return new CustomGenerator(this.rawText, scramble);
  }
}
