import { FunctionComponent } from 'react';
import preval from 'preval.macro';

import './BottomNavigation.scss';
import { getAppName } from '../../../util/app-name';

type BottomNavigationProps = {
  currentModal: string;
  setCurrentModal: (modalName: string) => void;
};

export const BottomNavigation: FunctionComponent<BottomNavigationProps> = ({
  currentModal,
  setCurrentModal,
}: BottomNavigationProps) => {
  const buildStamp = preval`module.exports = new Date().toLocaleString();`;

  return (
    <div className="BottomNavigation">
      <div className="fine-print">
        <span>Copyright &copy; 2023- {getAppName()}.app</span>
        <span>Built on {buildStamp}</span>
      </div>
    </div>
  );
};
