import React, { FunctionComponent } from 'react';
import './DrillSegmentsChart.scss';
import { DrillMeasurement } from '../../../model/DrillMeasurement';
import { Section } from '../../../model/Section';

type DrillSegmentsChartProps = {
  drillMeasurement: DrillMeasurement | null;
  onSectionFocused: (sectionIndex: number) => void;
  focusedSectionIndex: number | null;
};

export const DrillSegmentsChart: FunctionComponent<DrillSegmentsChartProps> = ({
  drillMeasurement,
  onSectionFocused,
  focusedSectionIndex,
}) => {
  if (!drillMeasurement || drillMeasurement.getSections().length < 1) {
    return null;
  }
  drillMeasurement.getSections()[0].getStartTimeStamp();

  const sectionDivs = drillMeasurement
    .getSections()
    .map((section: Section, sectionIndex) => {
      const color = section.getErrorCount() > 0 ? 'red' : 'black';
      const focusClass = sectionIndex === focusedSectionIndex ? ' focused' : '';
      const style = {
        color: color,
      };
      return (
        <span
          className={'measurement-section' + focusClass}
          key={`${sectionIndex}`}
          style={style}
          onMouseEnter={() => onSectionFocused(sectionIndex)}
        >
          {section.getTarget()}
        </span>
      );
    });

  return <div className="DrillSegmentsChart">{sectionDivs}</div>;
};
