import { PatternGenerator } from './PatternGenerator';
import { FunctionComponent } from 'react';
import './PatternGeneratorEditor.scss';

type PatternGeneratorEditorProps = {
  generator: PatternGenerator;
  onGeneratorChanged: (generator: PatternGenerator) => void;
};

export const PatternGeneratorEditor: FunctionComponent<
  PatternGeneratorEditorProps
> = ({ generator, onGeneratorChanged }) => {
  const onChangePattern = (event: React.FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;

    const entireNewValue =
      generator.getBeforePattern() + newValue + generator.getAfterPattern();

    if (entireNewValue.match(generator.getValidityExpression())) {
      const newGenerator = generator.patchPattern(newValue);
      onGeneratorChanged(newGenerator);
    }
  };

  const onChangeMinimumLength = (event: React.FormEvent<HTMLInputElement>) => {
    const newMin = parseInt(event.currentTarget.value, 10);
    const newGenerator = generator.patchMinimumLength(newMin);
    onGeneratorChanged(newGenerator);
  };

  const onChangeMaximumLength = (event: React.FormEvent<HTMLInputElement>) => {
    const newMax = parseInt(event.currentTarget.value, 10);
    const newGenerator = generator.patchMaximumLength(newMax);
    onGeneratorChanged(newGenerator);
  };

  const onChangeCommon = () => {
    const newGenerator = generator.patchCommon(!generator.getCommonOnly());
    onGeneratorChanged(newGenerator);
  };

  return (
    <div className="PatternGeneratorEditor">
      {!generator.isFixed() && (
        <>
          <div className="cell">
            <div className="label">Pattern</div>
            <input
              className="pattern-input"
              type="string"
              onChange={onChangePattern}
              value={generator.getPattern()}
            />
          </div>
          <div className="cell">
            <div className="label">and</div>
          </div>
        </>
      )}
      <div className="cell">
        <div className="label">Length</div>
        <input
          className="length-input"
          type="number"
          min="1"
          onChange={onChangeMinimumLength}
          value={generator.getMinimumLength()}
        />
        <span>to</span>
        <input
          className="length-input"
          type="number"
          min="1"
          onChange={onChangeMaximumLength}
          value={generator.getMaximumLength()}
        />
        <span>letters</span>
      </div>
      <div className="cell">
        <div className="label">Yields</div>
        <div className="unique-count">
          {generator.getUniqueWordCount()} unique words
        </div>
        <div className="common-only">
          <input
            type="checkbox"
            onChange={onChangeCommon}
            checked={generator.getCommonOnly()}
          />
          <span>Common words</span>
        </div>
      </div>
    </div>
  );
};
