import { FunctionComponent } from 'react';
import './StaticContent.scss';
import { getFullAppName } from '../../../util/app-name';

export const Privacy: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">Privacy</div>
      <p>
        If you choose to login then the app has your email address and any
        associated name or handle, but it does not share email addresses with
        any other parties and I will never reach out to you unless you report a
        bug or request a new feature. (which you should totally do, at{' '}
        <a href="mailto:ct@typingpractice.app">ct@typingpractice.app</a>)
      </p>
      <p>
        If you do login, then the data from your typing sessions will be
        accessible to you from any computers as you login.
      </p>
      <p>
        At some point I would like to do some data aggregation to enable pattern
        analysis across typists and to allow us all to see what different speeds
        look like.
      </p>
      <p>Any aggregation will be completely anonymous.</p>
      <p>
        Same goes for any sort of leaderboard or recognition of accomplishments.
        If such features happen they will be opt-in and based on an explicitly
        public handle rather than a real email or name.
      </p>
      <div className="static-content-title">Cookies</div>
      <p>
        TypingPractice.app does not use any tracking cookies. It does use Google
        Analytics in a non-consent mode such that session times (ten digit unix
        time stamps) are stored without personal or tracking identifiers.
      </p>
      <div className="static-content-title">Local Storage</div>
      <p>
        {getFullAppName()} uses HTML 5 localStorage to maintain a consistent
        guest account in your browser profile. This enables you to add to your
        typing history from day to day. There is no other use for this storage.
      </p>
      <div className="static-content-title">Learn More</div>
      <p>
        There are a lot of great resources on the web for learning about
        cookies, localStorage and privacy in general. Modern web browsers also
        let you check for yourself! Take a look at the cookies, localStorage and
        the data flowing in the network tab of your browser's built in developer
        tools.
      </p>
    </div>
  );
};
