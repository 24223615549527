import { SectionListGenerator } from '../../../model/SectionListGenerator';
import { Goal } from '../../../model/Goal';
import { Section } from '../../../model/Section';
import { commonWords } from '../../../words/common';
import { vimWords } from '../../../words/vimwords';
import { shuffle } from '../../../util/shuffle';

export class PatternGenerator implements SectionListGenerator {
  private name: string;
  private fixed: boolean;
  private pattern: string;
  private beforePattern: string;
  private afterPattern: string;
  private validityExpression: RegExp;
  private minimumLength: number;
  private maximumLength: number;
  private words: string[];
  private scramble: boolean;
  private currentIndex = 0;
  private commonOnly: boolean;

  constructor(
    name: string,
    fixed: boolean,
    pattern: string,
    beforePattern: string,
    afterPattern: string,
    validityExpression: RegExp,
    minimumLength: number,
    maximumLength: number,
    scramble: boolean,
    commonOnly: boolean
  ) {
    this.name = name;
    this.fixed = fixed;
    this.pattern = pattern;
    this.beforePattern = beforePattern;
    this.afterPattern = afterPattern;
    this.validityExpression = validityExpression;
    this.minimumLength = minimumLength;
    this.maximumLength = Math.max(minimumLength, maximumLength);
    this.scramble = scramble;
    this.commonOnly = commonOnly;

    const sourceWords = commonOnly ? commonWords : vimWords;
    const rawWords = sourceWords.split('\n').filter((word) => {
      const entirePattern = beforePattern + pattern + afterPattern;
      return (
        word.length >= this.minimumLength &&
        word.length <= this.maximumLength &&
        !!word.match(entirePattern)
      );
    });

    this.words = this.scramble ? shuffle(rawWords) : rawWords;
  }

  getName(): string {
    return this.name;
  }

  isFixed(): boolean {
    return this.fixed;
  }

  hasSections(): boolean {
    return this.words.length > 0;
  }

  getSections(goal: Goal): Section[] {
    const goalWordCount =
      (goal.getGoalWPM() / 60) * goal.getGoalTimeInSeconds();

    if (this.words.length === 0) {
      return [];
    }

    const sectionWords: string[] = [];
    while (sectionWords.length < goalWordCount) {
      const needed = goalWordCount - sectionWords.length;
      const goalIndex = this.currentIndex + needed + 1;
      const newIndex =
        goalIndex < this.words.length ? goalIndex : this.words.length;
      sectionWords.push(...this.words.slice(this.currentIndex, newIndex));
      this.currentIndex = newIndex < this.words.length ? newIndex : 0;
    }

    return Section.getSections(sectionWords);
  }

  getUniqueWordCount(): number {
    return this.words.length;
  }

  getScramble() {
    return this.scramble;
  }

  isScrambleSupported() {
    return true;
  }

  getMinimumLength() {
    return this.minimumLength;
  }

  getMaximumLength() {
    return this.maximumLength;
  }

  getCommonOnly() {
    return this.commonOnly;
  }

  getPattern() {
    return this.pattern;
  }

  getBeforePattern() {
    return this.beforePattern;
  }

  getAfterPattern() {
    return this.afterPattern;
  }

  getValidityExpression() {
    return this.validityExpression;
  }

  patchPattern(pattern: string) {
    if (this.fixed) {
      console.error(
        'Something tried to change the pattern of a fixed pattern generator'
      );

      return this;
    }

    return new PatternGenerator(
      this.name,
      this.fixed,
      pattern,
      this.beforePattern,
      this.afterPattern,
      this.validityExpression,
      this.minimumLength,
      this.maximumLength,
      this.scramble,
      this.commonOnly
    );
  }

  patchMinimumLength(minimumLength: number) {
    return new PatternGenerator(
      this.name,
      this.fixed,
      this.pattern,
      this.beforePattern,
      this.afterPattern,
      this.validityExpression,
      minimumLength,
      this.maximumLength,
      this.scramble,
      this.commonOnly
    );
  }

  patchMaximumLength(maximumLength: number) {
    return new PatternGenerator(
      this.name,
      this.fixed,
      this.pattern,
      this.beforePattern,
      this.afterPattern,
      this.validityExpression,
      this.minimumLength,
      maximumLength,
      this.scramble,
      this.commonOnly
    );
  }

  patchScramble(scramble: boolean) {
    return new PatternGenerator(
      this.name,
      this.fixed,
      this.pattern,
      this.beforePattern,
      this.afterPattern,
      this.validityExpression,
      this.minimumLength,
      this.maximumLength,
      scramble,
      this.commonOnly
    );
  }

  patchCommon(commonOnly: boolean) {
    return new PatternGenerator(
      this.name,
      this.fixed,
      this.pattern,
      this.beforePattern,
      this.afterPattern,
      this.validityExpression,
      this.minimumLength,
      this.maximumLength,
      this.scramble,
      commonOnly
    );
  }
}
