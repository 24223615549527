import { FunctionComponent } from 'react';
import './Link.scss';

type ActionLinkProps = {
  text: string;
  onClick: () => void;
};

export const ActionLink: FunctionComponent<ActionLinkProps> = ({
  text,
  onClick,
}: ActionLinkProps) => {
  return (
    <div>
      <div className="link ActionLink">
        <div className="link-text" onClick={onClick}>
          {text}
        </div>
      </div>
    </div>
  );
};
