/*
A Section represents both the section of text that should be typed and
the progress of the typist along the section.

The currentPosition starts at -1 indicating no progress and proceeds through the length-1
 */
import { Press } from './Press';

export type SectionDTO = {
  target: string;
  unixStart: number;
  pressList: Press[];
};

export class Section {
  private unixStart: number;
  private readonly target: string;
  private typed: string;
  private currentPosition: number;
  private readonly wordCount: number;
  private pressList: Press[];

  constructor(target: string) {
    this.target = target;
    this.wordCount = target.trim().split(' ').length;

    this.typed = '';
    this.currentPosition = -1;
    this.pressList = [];
    this.unixStart = 0;
  }

  public getTarget(): string {
    return this.target;
  }

  public getTyped(): string {
    return this.typed;
  }

  public isComplete(): boolean {
    return this.currentPosition >= this.target.length - 1;
  }

  public isCorrect(): boolean {
    if (this.currentPosition === -1) {
      return true;
    }

    return (
      this.target.slice(0, this.currentPosition + 1) ===
      this.typed.slice(0, this.currentPosition + 1)
    );
  }

  public type({ key, code }: React.KeyboardEvent): void {
    if (this.unixStart === 0) {
      this.unixStart = Date.now();
    }

    if (code === 'Backspace') {
      if (this.typed.length > 0) {
        this.typed = this.typed.substring(0, this.typed.length - 1);
        this.currentPosition--;
      }
      return;
    }

    // ignore non character keys
    if (key.length > 1) {
      return;
    }

    this.currentPosition++;
    this.replaceInTyped(key);

    const desired = this.target.slice(
      this.currentPosition,
      this.currentPosition + 1
    );

    this.pressList.push({
      key,
      stamp: performance.now(),
      error: key !== desired,
    });
  }

  public getLastTimeStamp(): number {
    if (this.pressList.length === 0) {
      return 0;
    }

    return this.pressList[this.pressList.length - 1].stamp;
  }

  public getStartTimeStamp(): number {
    if (this.pressList.length === 0) {
      return 0;
    }

    return this.pressList[0].stamp;
  }
  public getWordCount(): number {
    return this.wordCount;
  }

  public calculateWPM(): number {
    const elapsedMinutes =
      (this.getLastTimeStamp() - this.getStartTimeStamp()) / 60000;
    return this.wordCount / elapsedMinutes;
  }

  public calculateCPS(): number {
    const elapsedSeconds =
      (this.getLastTimeStamp() - this.getStartTimeStamp()) / 1000;
    return this.target.length / elapsedSeconds;
  }

  public getErrorCount(): number {
    return this.pressList.reduce((accumulator: number, press: Press) => {
      return accumulator + (press.error ? 1 : 0);
    }, 0);
  }

  public getPressedList() {
    return this.pressList;
  }

  public getPressedText() {
    return this.pressList.reduce((accumulator: string, press) => {
      return accumulator + press.key;
    }, '');
  }

  public getUnixStart() {
    return this.unixStart;
  }

  public toDTO(): SectionDTO {
    return {
      target: this.target,
      unixStart: this.unixStart,
      pressList: this.pressList,
    };
  }

  public static getSections(words: string[]) {
    return words.map((raw: string, index: number) => {
      const spacer: string = index === words.length - 1 ? '' : ' ';
      return new Section(raw.trim() + spacer);
    });
  }

  private replaceInTyped(value: string) {
    if (this.currentPosition < this.typed.length) {
      this.typed =
        this.typed.substring(0, this.currentPosition) +
        value +
        this.typed.substring(this.currentPosition + value.length);
    } else {
      this.typed = this.typed + value;
    }
  }

  private reset() {
    this.typed = '';
    this.currentPosition = -1;
  }

  public static fromDTO(dto: SectionDTO): Section {
    const newSection = new Section(dto.target);
    newSection.pressList = dto.pressList;
    newSection.unixStart = dto.unixStart;
    newSection.typed = dto.target;
    return newSection;
  }
}
