import { FunctionComponent, useState } from 'react';

import { DrillMeasurementTrendChart } from './DrillMeasurementTrendChart/DrillMeasurementTrendChart';
import { DrillSummary } from './DrillSummary/DrillSummary';
import { DrillSegmentsChart } from './DrillSegmentsChart/DrillSegmentsChart';
import { DrillMeasurement } from '../../model/DrillMeasurement';
import { SectionSummary } from './SectionSummary/SectionSummary';
import { DrillDetailChart } from './DrillDetailChart/DrillDetailChart';
import { Goal } from '../../model/Goal';

type ReviewProps = {
  measurements: DrillMeasurement[];
  goal: Goal;
  selectedMeasurement: DrillMeasurement | null;
  onSelectMeasurement: (newMeasurement: DrillMeasurement | null) => void;
  onShowMeasurement: (newMeasurement: DrillMeasurement | null) => void;
};

export const Review: FunctionComponent<ReviewProps> = ({
  measurements,
  goal,
  selectedMeasurement,
  onSelectMeasurement,
  onShowMeasurement,
}) => {
  const [focusedSectionIndex, setFocusedSectionIndex] = useState<number>(0);
  return (
    <div className="Review">
      {measurements.length > 0 && (
        <>
          <div className="chart-label">Recent Drills</div>
          <DrillMeasurementTrendChart
            drillMeasurements={measurements}
            goal={goal}
            onSelectMeasurement={onSelectMeasurement}
            onShowMeasurement={onShowMeasurement}
            shownMeasurement={selectedMeasurement}
          />
          <div className="explanation">Click a bar to select it</div>
        </>
      )}
      <DrillSummary drillMeasurement={selectedMeasurement} />
      <DrillSegmentsChart
        drillMeasurement={selectedMeasurement}
        onSectionFocused={setFocusedSectionIndex}
        focusedSectionIndex={focusedSectionIndex}
      />
      <SectionSummary
        drillMeasurement={selectedMeasurement}
        focusedSectionIndex={focusedSectionIndex}
      />
      <DrillDetailChart
        drillMeasurement={selectedMeasurement}
        onSectionFocused={setFocusedSectionIndex}
        focusedSectionIndex={focusedSectionIndex}
      />
    </div>
  );
};
